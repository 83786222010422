import { Component, OnInit } from '@angular/core';
import { GlobalesService } from 'src/app/services/globales.service';
import { ComunityService } from 'src/app/services/comunity.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { PdaService } from '../../../../../services/pda.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
declare function laddaStart(params: string);
declare function laddaStop(params: string);

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-appointments-history',
  templateUrl: './appointments-history.component.html',
  styles: [
  ],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass:MomentDateAdapter , deps: [MAT_DATE_LOCALE]},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},]
})



export class AppointmentsHistoryComponent implements OnInit {
  
  cargando=true;
  listLog:any[]=[];
  pagina=0;
  fechaInicio:any;
  fechaFinal:any;
  fSearch:string;
  fMulti:any[];
  viewFilter=false;
  opcionesMulti = [
                    {name: 'Create PDA', code: '1'},
                    {name: 'Create Appointment', code: '3'},
                    {name: 'Create Voyage', code: '9'}
                  ]
  maxDate= new Date;
  criterio :any;
  subscription: Subscription;
  constructor( private pdaService: PdaService,
               private usuarioService: UsuarioService,
               private globales: GlobalesService,
               private comunityService: ComunityService,
               private router:Router,
               private actRouter:ActivatedRoute) { 

    this.comunityService.changeData('PDA & Call Invitation', 'Task Log');

    this.criterio={
      texto:this.fSearch?this.fSearch:'',
      fechaInicio:this.fechaInicio?this.fechaInicio:'',
      fechaFinal:this.fechaFinal?this.fechaFinal:'',
      tipo:this.fMulti?this.fMulti:''
    };

    this.getLogOp();

  }

  ir(item){

    let link = this.globales.infoLogOp.find(x=>x.tipo===item.tipo).link;
    if(link){
      this.router.navigate(['main/'+link,item.idInteres]);
    }

  }

  cleanPicker() {
    this.criterio.fechaInicio = '';
    this.criterio.fechaFinal = '';
    this.getLogOp();

  }

  getLogOp(cargar=true){
    this.cargando=cargar;

    if (this.fMulti) {
      this.criterio.tipo = this.fMulti.map((x) => x.code)?.join(',');
     }

    let datos={
     idEmpresa:this.usuarioService.usuario.empresa.idEmpresa,
     pagina:this.pagina,
     ...this.criterio
    }

    if(this.subscription){
      this.subscription.unsubscribe();
    }


    this.subscription= this.pdaService.getLogOp(datos)
                    .subscribe((res:any)=>{
      
                      this.cargando=false;
                
                      this.listLog =res;
                      console.log(this.listLog);
                
                    },(err)=>{
                      this.cargando=false;
                      this.globales.checkMsgError(err);
                    })
  }

  cargarMas(){
    laddaStart('.btn_ladda');

    let datos={
     idEmpresa:this.usuarioService.usuario.empresa.idEmpresa,
     pagina:this.listLog[this.listLog.length-1].pagina+1,
     ...this.criterio
    }
    this.pdaService.getLogOp(datos).subscribe((res:any[])=>{

    laddaStop('.btn_ladda');
      this.listLog.push(...res);

    },err=>{
          laddaStop('.btn_ladda');

      this.globales.checkMsgError(err)
    });
  }


  ngOnInit(): void {
  }

}
